import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CardBlock } from '../../molecules';

import { Formik } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import { TextInput, Select, Modal } from '../../molecules';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Loader, Button, Checkbox } from '../../atoms';
import './updatePersonalInfo.scss';
import { setUserPersonalInfo, setFirstNamePersonalInfo, updatePersonalInfo } from '../../redux/user/actions';

const UpdatePersonalInformation = (props) => {
  const dispatch = useDispatch();
  const managePersonalInfo = useSelector((reduxState) => reduxState.user.managePersonalInfo);
  const user = useSelector((reduxState) => reduxState.user);
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState('');
  const [options, setOptions] = useState([]);
  const Countries = [{ label: 'Canada', value: 'CAN' }];
  const [emailChecked, setEmailChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentchecked, setContentChecked] = useState(false);
  const [postalCode, setPostalCode] = useState('');

  useEffect(() => {
    getCountryData();
  }, []);

  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (managePersonalInfo?.updatePersonalInfoEnabled) {
      if (managePersonalInfo?.userPersonalInfo?.MarktOptInOut === 'on') {
        setContentChecked(true);
      }
      if (managePersonalInfo?.userPersonalInfo?.TraxOptInOut === 'on') {
        setEmailChecked(true);
      }
      document.body.scrollTop = 0;
    }
  }, [managePersonalInfo]);

  useEffect(() => {
    countryData &&
      countryData?.Areas &&
      countryData.Areas.map((data) => {
        let obj = {
          label: data.Name,
          value: data.Id,
        };
        options.push(obj);
      });
  }, [countryData]);

  const getCountryData = () => {
    api
      .getCountryAreas()
      .then((response) => {
        if (response.status === 200) {
          setCountryData(response?.data);
        }
      })
      .catch(() => { });
  };

  const discardChangesHandler = () => {
    dispatch(
      setUserPersonalInfo({
        updatePersonalInfoEnabled: false,
        userPersonalInfo: {},
        submittedInfo: {},
      })
    );
  };

  const onEmailCheckedChange = (e) => {
    if (e) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
    setEmailChecked(e);
  };

  const onContentCheckedChange = (e) => {
    setContentChecked(e);
  };

  return [
    managePersonalInfo?.updatePersonalInfoEnabled ? (
      <>
        <div className="update-personal-info">
          <CardBlock title={props.fields.Heading.value}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                FirstName: managePersonalInfo?.userPersonalInfo?.Name?.FirstName,
                LastName: managePersonalInfo?.userPersonalInfo?.Name?.LastName,
                StreetAdress:
                  managePersonalInfo?.userPersonalInfo?.Address?.Line2 != null
                    ? managePersonalInfo?.userPersonalInfo?.Address?.Line2
                    : '',
                AptSuiteBuilding:
                  managePersonalInfo?.userPersonalInfo?.Address?.Line1 != null
                    ? managePersonalInfo?.userPersonalInfo?.Address?.Line1
                    : '',
                City: managePersonalInfo?.userPersonalInfo?.Address?.City,
                Province:
                  options &&
                  options.length > 0 &&
                  options
                    .filter((e) => {
                      return e.value === managePersonalInfo?.userPersonalInfo?.Address?.Area?.Id;
                    })
                    .map((val) => {
                      return val.value;
                    })[0],
                Country: Countries[0].value,
                PostalCode: managePersonalInfo?.userPersonalInfo?.Address?.PostalCode,
                PrimaryPhoneNo: managePersonalInfo?.userPersonalInfo?.PhoneNumber,
                SecondaryPhoneNo: managePersonalInfo?.userPersonalInfo?.PhoneNumber2,
              }}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                FirstName: Yup.string()
                  .transform((value) => value.trim())
                  .required(props.fields.FnameError.value)
                  .max(25, props.fields.FirstnameValidate.value)
                  .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.FirstnameValidate.value),
                LastName: Yup.string()
                  .transform((value) => value.trim())
                  .required(props.fields.LnameError.value)
                  .max(25, props.fields.LastNameValidate.value)
                  .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,25}$/, props.fields.LastNameValidate.value),
                StreetAdress: Yup.string()
                  .transform((value) => value.trim())
                  .required(props.fields.StreetError.value)
                  .max(50, props.fields.StreetAdressValidate.value)
                  .matches(
                    /^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,50}$/,
                    props.fields.StreetAdressValidate.value
                  ),
                AptSuiteBuilding: Yup.string()
                  .transform((value) => value.trim())
                  .max(50, props.fields.AptsuitebuildingValidate.value)
                  .matches(
                    /^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{0,50}$/,
                    props.fields.AptsuitebuildingValidate.value
                  ),
                City: Yup.string()
                  .transform((value) => value.trim())
                  .required(props.fields.CityError.value)
                  .max(50, props.fields.cityValidate.value)
                  .matches(/^[a-zA-Z0-9àâäæèéêëîïôœùûüÿçÀÂÄÆÈÉÊËÎÏÔŒÙÛÜŸÇ ,.'-]{1,50}$/, props.fields.cityValidate.value),
                PostalCode: Yup.string()
                  .required(props.fields.postalError.value)
                  .matches(
                    /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/,
                    props.fields.PostalCodeerror.value
                  ),
                Province: Yup.string().required(props.fields.provinceValidate.value),
                PrimaryPhoneNo: Yup.string()
                  .required(props.fields.Primaryphonerror.value)
                  .matches(
                    /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
                    props.fields.primaryphonedigiterror.value
                  ),
                SecondaryPhoneNo: Yup.string().matches(
                  /^\(?([0-9]{3})\)?[\s-●]?([0-9]{3})[\s-●]?([0-9]{4})$/,
                  props.fields.secondaryphonenoerrorValidate.value
                ),
              })}
              onSubmit={(values) => {
                let payload = {
                  CustomerId: user?.customerId,
                  FirstName: values.FirstName,
                  LastName: values.LastName,
                  StreetAddress: values.StreetAdress,
                  Apartment: values.AptSuiteBuilding,
                  AreaId: values.Province,
                  AreaName: options
                    .filter((e) => {
                      return e.value === values.Province;
                    })
                    .map((val) => {
                      return val.label;
                    })[0],
                  AreaCode: values.Province,
                  CountryId: 'CAN',
                  City: values.City,
                  PostalCode: values.PostalCode,
                  Province: values.Province,
                  CountryName: 'Canada',
                  PrimaryPhone: values.PrimaryPhoneNo,
                  SecondaryPhone: values.SecondaryPhoneNo,
                  TraxOptInOut: emailChecked ? 'on' : 'off',
                  MarktOptInOut: contentchecked ? 'on' : 'off',
                  Email: user?.email,
                };
                setIsLoading(true);
                api
                  .updatePersonalData(payload)
                  .then((response) => {
                    //console.log(response);
                    if (response?.data?.Success) {
                      dispatch(updatePersonalInfo({
                        newUpdatePersonalInfo: response?.data?.UpdatedPersonalData
                      }));
                      dispatch(setFirstNamePersonalInfo(response.data.UpdatedPersonalData.Name.FirstName));
                      dispatch(updatePersonalInfo({
                        firstName: response?.data?.UpdatedPersonalData?.Name?.FirstName,
                        lastName: response?.data?.UpdatedPersonalData?.Name?.LastName,
                        phoneNumber: response?.data?.UpdatedPersonalData?.PhoneNumber,
                        currentShippingDetails: response?.data?.UpdatedPersonalData?.Address
                      }));
                      dispatch(
                        setUserPersonalInfo({
                          updatePersonalInfoEnabled: false,
                          userPersonalInfo: {},
                          submittedInfo: { submit: true, success: true },
                        })
                      );
                      setIsLoading(false);
                    } else {
                      dispatch(
                        setUserPersonalInfo({
                          updatePersonalInfoEnabled: false,
                          userPersonalInfo: {},
                          submittedInfo: { submit: true, success: false },
                        })
                      );
                      setIsLoading(false);
                    }
                  })
                  .catch((err) => {
                    //console.log(err);
                    dispatch(
                      setUserPersonalInfo({
                        updatePersonalInfoEnabled: false,
                        userPersonalInfo: {},
                        submittedInfo: { submit: true, success: false },
                      })
                    );
                    setIsLoading(false);
                  });
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <p className="required-textcontent">{props.fields.RequiredField.value}</p>
                    <div className="textinput-flex">
                      <div className="firstinput-wrapper">
                        <TextInput
                          type="text"
                          value={values.FirstName}
                          name="FirstName"
                          errors={errors}
                          touched={touched}
                          required={true}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.Firstnamelabel}
                        />
                      </div>
                      <div className="secondinput-wrapper">
                        <TextInput
                          type="text"
                          value={values.LastName}
                          name="LastName"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.LastnameLabel}
                        />
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="firstinput2-wrapper">
                        <TextInput
                          type="text"
                          value={values.StreetAdress}
                          name="StreetAdress"
                          required={true}
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.streetLabel}
                        />
                      </div>
                      <div className="secondinput2-wrapper">
                        <TextInput
                          type="text"
                          value={values.AptSuiteBuilding}
                          name="AptSuiteBuilding"
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.Aptlabel}
                        />
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="cityinput-wrapper">
                        <TextInput
                          type="text"
                          value={values.City}
                          name="City"
                          required={true}
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.Citylabel}
                        />
                      </div>
                      <div className="provinceinput-wrapper">
                        <div className="province-margin-perInfo">
                          <Select
                            name="Province"
                            errors={errors}
                            touched={touched}
                            options={options}
                            label={props.fields.ProvinceLabel.value}
                            labelA11y={props.fields.ProvinceLabelA11y.value}
                            defaultValue={
                              options &&
                              options.length > 0 &&
                              options
                                .filter((e) => {
                                  return (
                                    e.value ===
                                    managePersonalInfo?.userPersonalInfo?.Address?.Area?.Id
                                  );
                                })
                                .map((val) => {
                                  return val.value;
                                })
                            }
                            defaultLabel={
                              options &&
                              options.length > 0 &&
                              options
                                .filter((e) => {
                                  return (
                                    e.value ===
                                    managePersonalInfo?.userPersonalInfo?.Address?.Area?.Id
                                  );
                                })
                                .map((val) => {
                                  return val.label;
                                })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="countryinput-wrapper">
                        {/* <div className="province-margin">
                          <Select
                            fullWidth
                            name="Country"
                            options={Countries}
                            label={props.fields.Countrylabel.value}
                            labelA11y={props.fields.CountrylabelA11y.value}
                            defaultValue={Countries[0].value}
                            defaultLabel={Countries[0].label}
                          />
                        </div> */}
                        <TextInput
                          type="Country"
                          value="Canada"
                          name="Country"
                          errors={errors}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.Countrylabel}
                          disabled={true}
                        />
                      </div>
                      <div className="postalinput-wrapper ">
                        <TextInput
                          type="text"
                          value={values.PostalCode}
                          name="PostalCode"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                            onPostalCodeChange(e);
                          }}
                          label={props.fields.PostalLabel}
                          bellowText={props.fields.Postalcodedesc}
                        />
                        {/* <p className="postal-code-desc">{props.fields.Postalcodedesc.value}</p> */}
                      </div>
                    </div>
                    <div className="textinput-flex">
                      <div className="countryinput-wrapper">
                        <TextInput
                          type="text"
                          value={values.PrimaryPhoneNo}
                          name="PrimaryPhoneNo"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.PrimaryphoneLabel}
                        />
                      </div>
                      <div className="postalinput-wrapper">
                        <TextInput
                          type="text"
                          value={values.SecondaryPhoneNo}
                          name="SecondaryPhoneNo"
                          errors={errors}
                          required={true}
                          touched={touched}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={props.fields.SecondaryPhone}
                        />
                      </div>
                    </div>
                    <div className="personal-info-divider"></div>
                    <div className="textinput-flex info-checkbox-padding">
                      <div>
                        <Checkbox
                          className="centeredCheckbox-label"
                          checked={contentchecked}
                          onChange={(e) => {
                            onContentCheckedChange(e);
                          }}
                          checkboxA11y={props.fields.ReceiveInfo.value}
                        >
                          <Text field={props.fields.ReceiveInfo} />
                        </Checkbox>
                      </div>
                    </div>
                    {props.fields.Receivemail.value === '' ? null :
                      <>
                        <div className="personal-info-divider"></div>
                        <div className="textinput-flex info-checkbox-padding">
                          <div>
                            <Checkbox
                              className="centeredCheckbox-label"
                              checked={emailChecked}
                              onChange={(e) => {
                                onEmailCheckedChange(e);
                              }}
                              checkboxA11y={props.fields.Receivemail.value}
                            >
                              <Text field={props.fields.Receivemail} />
                            </Checkbox>
                          </div>
                        </div>
                      </>
                    }

                    <div className={`info-btn-flex ${!props.fields.Receivemail.value && 'btn-receiveMail-empty'}`}>
                      <div className="info-btn-padding ">
                        <Button
                          type="submit"
                          buttonTextA11y={props.fields.SavechangesLabelA11y.value}
                        >
                          <Text field={props.fields.SavechangesLabel} />
                        </Button>
                      </div>
                      <div>
                        <Button
                          white
                          firstOrder
                          buttonTextA11y={props.fields.DiscardChangesLabelA11y.value}
                          onClick={() => discardChangesHandler()}
                        >
                          <Text field={props.fields.DiscardChangesLabel} />
                        </Button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </CardBlock>
        </div>

        {
          <Modal
            title={props.fields.Optoutmail.value}
            text={props.fields.OptoutmailDesc.value}
            textSubmit={props.fields.Closelabel.value}
            useRevert
            disableBackdropClick
            disableEscapeKeyDown
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        }
      </>
    ) : null,
    isLoading ? <Loader /> : null,
  ];
};

export default UpdatePersonalInformation;
